import React from 'react';

const Login = React.lazy(() => import('./Authentication/login.js'));
const Signup = React.lazy(() => import('./Authentication/signup.js'));
const Forgot = React.lazy(() => import('./Authentication/forgot.js'));

const route = [
    { path: '/auth/login', exact: true, name: 'Login', component: Login },
    { path: '/auth/novaconta', exact: true, name: 'Entrar', component: Signup },
    { path: '/auth/reenviar', exact: true, name: 'Entrar', component: Forgot },
];

export default route;