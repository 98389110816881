import 'font-awesome/scss/font-awesome.scss';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import React, { Suspense, useLayoutEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import Aux from '../hoc/_Aux';
import Loadable from 'react-loadable';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import { SessionProvider } from './contexts/session';
import { UtilsProvider } from './contexts/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import routes from '../UnauthenticatesRoutes';
import { setContext } from '@apollo/client/link/context';

// start apollo

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem('accessToken');
      return {
        authorization: token ? 'Bearer ' + token : '',
      };
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      Authorization: token ? 'Bearer ' + token : '',
    },
  };
});

export const client = new ApolloClient({
  connectToDevtools: true,
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

// end apollo

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
});

const App = () => {
  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  useLayoutEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token && !window.location.href.toString().includes('login')) {
      window.location.href = '/auth/login';
    }

    return () => {
      console.log('bye bye!');
    };
  }, []);

  return (
    <Aux>
      <UtilsProvider>
        <SessionProvider>
          <ApolloProvider client={client}>
            <ScrollToTop>
              <Suspense fallback={<Loader />}>
                <Switch>
                  {menu}
                  <Route path="/" component={AdminLayout} />
                </Switch>
              </Suspense>
            </ScrollToTop>
          </ApolloProvider>
        </SessionProvider>
      </UtilsProvider>
    </Aux>
  );
};

export default App;
