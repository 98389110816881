import React, {
  ReactChildren,
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { LoginMutation } from '../../services/gql/generated';

interface SessionContextProps {
  session: Record<string, string | undefined>;
  addSession(key: string, value: string, local?: boolean): void;
  removeSession(key: string): void;
  clearSession(): void;
  idsystemsLogin: LoginMutation['login'];
}

export const SessionContext = createContext<SessionContextProps>(
  new Proxy(
    {
      session: {},
      addSession() {},
      removeSession() {},
      clearSession() {},
      idsystemsLogin: undefined,
    },
    {
      get() {
        throw new Error('<SessionProvider> não encontrado');
      },
    }
  )
);

function SessionContextProvider(props: { children: ReactChildren }) {
  const [_session, setSession] = useState(() => {
    const result: Record<string, string | undefined> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        result[key] = localStorage.getItem(key)!;
      }
    }
    return result;
  });

  const session = useMemo(
    () =>
      new Proxy(_session, {
        has(target, key) {
          if (typeof key == 'symbol') return false;
          return (
            Object.prototype.hasOwnProperty.call(target, key) ||
            localStorage.getItem(key) != null
          );
        },
        ownKeys() {
          const result: string[] = [];
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key) {
              result.push(key);
            }
          }
          return result;
        },
        getOwnPropertyDescriptor(target, key) {
          if (typeof key != 'symbol') {
            const value = this.get!(target, key, target) as string | undefined;

            if (value)
              return { value, writable: false, configurable: true, enumerable: true };
          }
          return undefined;
        },
        get(target, key): string | undefined {
          if (typeof key == 'symbol') return;

          if (Object.prototype.hasOwnProperty.call(target, key)) {
            return target[key];
          }
          const value = localStorage.getItem(key);
          if (value) return value;
        },
      }),
    [_session]
  );

  const addSession = useCallback(
    (key: string, value: string, local: boolean = false) => {
      setSession((prev) => ({ ...prev, [key]: value }));
      if (local) {
        localStorage.setItem(key, value);
      }
    },
    []
  );

  const removeSession = useCallback((key: string) => {
    setSession((prev) =>
      Object.fromEntries(Object.entries(prev).filter(([k]) => k !== key))
    );
    localStorage.removeItem(key);
  }, []);

  const clearSession = useCallback(() => {
    localStorage.clear();
    setSession({});
    window.location.href = '/idsystems';
  }, []);

  const idsystemsLogin = useMemo(() => {
    if (session.idsystems) {
      return JSON.parse(session.idsystems);
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        addSession,
        removeSession,
        clearSession,
        idsystemsLogin,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}
export const SessionProvider = memo(SessionContextProvider);
